import React from 'react';

const Footer = () => (
  <footer className="py-16 mt-16 md:mt-32 bg-gray-100">
    <div className={'mx-auto container'}>
      <div className="flex">
        <div className="flex-1 px-3">
          <h2 className="text-lg font-semibold">About Us</h2>
          <p className="mt-5">Nozzle Corpporation</p>
        </div>
      </div>
    </div>
  </footer>
);

export default Footer;
