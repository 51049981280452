import React from 'react';
import ButtonLink from '../ButtonLink';
import { Link } from 'gatsby';

const Header = () => {
  const logo = "{ osheeru }";
  return (
    <header className="sticky top-0 bg-white shadow z-50">
      <div
        className="container hidden sm:flex flex-row items-start mx-auto py-4 px-8 sm:flex-column sm:justify-between sm:items-center">
        <div>
          <Link to={'/'} className={'text-4xl text-gray-900 hover:text-gray-900'}>{logo}</Link>
        </div>
        <div className="flex items-center">
          <div className="flex mt-4 sm:mt-0">
            <Link className="px-4" to={'/plan'}>料金プラン</Link>
          </div>
          <div>
            <ButtonLink className="text-sm rounded-3xl" path={'/contact'}>お問い合わせ</ButtonLink>
          </div>
        </div>
      </div>
      <div className="container block sm:hidden items-center pl-12 py-4">
        <Link to={'/'}>
          <Link to={'/'} className={'text-xl text-gray-900 hover:text-gray-900'}>{logo}</Link>
        </Link>
      </div>
    </header>
  );
};

export default Header;
