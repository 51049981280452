import React from 'react';
import Layout from '../components/layout/Layout';
import '../css/circle-mark.css';
import '../css/cross-mark.css';
import Plan from '../components/Plan';

const Index = () => (
  <Layout>
    <section className="py-16 lg:pt-48">
      <div className="container mx-auto text-center">
        <h2 className="text-3xl lg:text-5xl font-semibold">料金プラン</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 mt-12">
           <Plan name={'スモール'}
                  description={'クリニックや飲食店、少人数からスモールスタートする方におすすめのプランです。'}
                  initialPrice={'初期費用なし'}
                  subscriptionPrice={'1万円'}
                  planOptions={starterPlanOptions}
                  planNumOfUsers={starterNumOfUsers}
                  planNumOfUploads={starterNumOfUploads}
                  headerClass={'bg-blue-500'}
          />
          <Plan name={'スタンダード'}
                description={'支店や部署をお持ちの中規模向け事業者さまにおすすめのプランです。'}
                initialPrice={'初期費用なし'}
                subscriptionPrice={'3万円'}
                planOptions={standardPlanOptions}
                planNumOfUsers={standardNumOfUsers}
                planNumOfUploads={standardNumOfUploads}
                headerClass={'bg-green-500'}
          />
          <Plan name={'プロフェッショナル'}
                description={'支店や部署が多い大規模事業者さまにおすすめのプランです。'}
                initialPrice={'初期費用なし'}
                subscriptionPrice={'10万円'}
                planOptions={enterprisePlanOptions}
                planNumOfUsers={enterpriseNumOfUsers}
                planNumOfUploads={enterpriseNumOfUploads}
                headerClass={'bg-orange-500'}
          />
        </div>
      </div>
    </section>
  </Layout>
);


const starterPlanOptions = [
  {
    name:  'マニュアル作成・編集',
    hasOption: true,
  },
  {
    name:  'マニュアル閲覧',
    hasOption: true,
  },
  {
    name:  '画像付きマニュアル',
    hasOption: true,
  },
  {
    name:  '動画付きマニュアル',
    hasOption: false,
  },
  {
    name:  'チェックリストマニュアル',
    hasOption: true,
  },
  {
    name:  'タスク実施結果アップロード',
    hasOption: false,
  },
  {
    name:  'QRコードでマニュアル検索',
    hasOption: true,
  },
  {
    name:  'バーコードでマニュアル検索',
    hasOption: true,
  },
  {
    name:  'マニュアル公開申請・承認',
    hasOption: false,
  },
];

const starterNumOfUsers = [
  {
    name: 'マニュアル閲覧アカウント数',
    value: '40人',
  },
  {
    name: 'マニュアル作成アカウント数',
    value: '10人',
  }
];

const starterNumOfUploads = [
  {
    name: 'アップロードできる画像数',
    value: '600枚',
  },
  {
    name: 'アップロードできる動画数',
    value: '無し',
  }
];

const standardPlanOptions = [
  {
    name:  'マニュアル作成・編集',
    hasOption: true,
  },
  {
    name:  'マニュアル閲覧',
    hasOption: true,
  },
  {
    name:  '画像付きマニュアル',
    hasOption: true,
  },
  {
    name:  '動画付きマニュアル',
    hasOption: true,
  },
  {
    name:  'チェックリストマニュアル',
    hasOption: true,
  },
  {
    name:  'タスク実施結果アップロード',
    hasOption: true,
  },
  {
    name:  'QRコードでマニュアル検索',
    hasOption: true,
  },
  {
    name:  'バーコードでマニュアル検索',
    hasOption: true,
  },
  {
    name:  'マニュアル公開申請・承認',
    hasOption: true,
  },
];

const standardNumOfUsers = [
  {
    name: 'マニュアル閲覧アカウント数',
    value: '150人',
  },
  {
    name: 'マニュアル作成アカウント数',
    value: '50人',
  }
];

const standardNumOfUploads = [
  {
    name: 'アップロードできる画像数',
    value: '3000枚',
  },
  {
    name: 'アップロードできる動画数',
    value: '1000動画',
  }
];

const enterprisePlanOptions = [
  {
    name:  'マニュアル作成・編集',
    hasOption: true,
  },
  {
    name:  'マニュアル閲覧',
    hasOption: true,
  },
  {
    name:  '画像付きマニュアル',
    hasOption: true,
  },
  {
    name:  '動画付きマニュアル',
    hasOption: true,
  },
  {
    name:  'チェックリストマニュアル',
    hasOption: true,
  },
  {
    name:  'タスク実施結果アップロード',
    hasOption: true,
  },
  {
    name:  'QRコードでマニュアル検索',
    hasOption: true,
  },
  {
    name:  'バーコードでマニュアル検索',
    hasOption: true,
  },
  {
    name:  'マニュアル公開申請・承認',
    hasOption: true,
  },
]

const enterpriseNumOfUsers = [
  {
    name: 'マニュアル閲覧アカウント数',
    value: '無制限',
  },
  {
    name: 'マニュアル作成アカウント数',
    value: '無制限',
  }
];

const enterpriseNumOfUploads = [
  {
    name: 'アップロードできる画像数',
    value: '無制限',
  },
  {
    name: 'アップロードできる動画数',
    value: '2000動画',
  }
];

export default Index;
