import React from 'react';
import Link from 'gatsby-link';

const sizes = {
  default: `py-3 px-8`,
  lg: `py-4 px-12`,
  xl: `py-5 px-16 text-lg`
};

const ButtonLink = ({ children, className = '', size, path }) => {
  return (
    <Link to={path}>
      <span
        className={`
          ${sizes[size] || sizes.default}
          ${className}
          bg-primary
          hover:bg-primary-darker
          rounded
          text-white
      `}
      >
        {children}
      </span>
    </Link>
    );
};

export default ButtonLink;
