import React from 'react';
import PlanCard, { PlanCardContent, PlanCardHeader } from './PlanCard';
import ButtonLink from './ButtonLink';


export const Plan = ({name, description, initialPrice, subscriptionPrice, planOptions, planNumOfUsers, planNumOfUploads, headerClass}) => {

  const options = planOptions || [];
  const users = planNumOfUsers || [];
  const uploads = planNumOfUploads || [];
  return (
    <PlanCard className="mb-8 mx-4">
      <PlanCardHeader className={`min-h-256px ${headerClass}`}>
        <div className={'text-white text-2xl font-semibold'}>
          {name}
        </div>
        <div className={'text-white text-md'}>
          {description}
        </div>
      </PlanCardHeader>
      <PlanCardContent className={'divide-y'}>
        <div className={'pb-4'}>
          <div className={'text-md'}>
            {initialPrice}
          </div>
          <div className="pt-4">
            <span className={'text-xl'}>月額</span><span className={'pl-4 font-semibold text-4xl text-red-500'}>{subscriptionPrice}</span>
          </div>
          <div className="pt-8">
            <ButtonLink size="lg" className={'rounded-xl'} path={'/contact'}>無料でお試し</ButtonLink>
          </div>
        </div>
        <div className={'mt-8 pt-4'}>
          {
            options.map((option, index) => {
              return (
                  <div key={index} className={'flex justify-between'}>
                    <div className={'mt-2'}>{option.name}</div>
                    <div>
                      { option.hasOption ? <span className={'circle-mark mt-2'} /> : <span className={'cross-mark'} /> }
                    </div>
                  </div>
              )
            })
          }
        </div>
        <div className={'mt-4 pt-4'}>
          {
            users.map((user, index) => {
              return (
                <div key={index} className={'flex justify-between'}>
                  <div className={'mt-2'}>{user.name}</div>
                  <div className={'mt-2'}>{user.value}</div>
                </div>
              )
            })
          }
        </div>
        <div className={'mt-4 pt-4'}>
          {
            uploads.map((upload, index) => {
              return (
                <div key={index} className={'flex justify-between'}>
                  <div className={'mt-2'}>{upload.name}</div>
                  <div className={'mt-2'}>{upload.value}</div>
                </div>
              )
            })
          }
        </div>
      </PlanCardContent>
    </PlanCard>
  )
}

export default Plan;